.iwgb {
  height: 2rem;
  margin-bottom: .5rem;
}

.itf {
  height: 3rem;
}

.policyLink {
  &:not(:last-of-type)::after {
    content: " · "
  }
}
