@import 'src/utils/utils';

.text-stratos {
  font-family: Stratos, $font-family-base;
}

.text-poppins {
  font-family: Poppins, $font-family-base;
}

$fontWeights: 500, 600, 700, 800;

@each $fontWeight in $fontWeights {
  .font-weight-#{$fontWeight} {
    font-weight: $fontWeight;
  }
}

$fontSizes: 18, 28, 56, 64;

@each $fontSize in $fontSizes {
  .font-size-#{$fontSize} {
    font-size: rem($fontSize);
  }
}
