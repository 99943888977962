.icon {
  transition: transform .5s;

  &.open {
    transform: rotate(90deg);
  }
}

.content {
  margin-left: 1.25em;
}
