@import 'src/utils/utils';

.drop {
  border: .25rem dashed $roovolt-green;
  color: $roovolt-purple;
  transition: border-color .2s;
  min-height: 10rem;
}

.accept {
  border-color: $roovolt-purple;
}
