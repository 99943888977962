@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;800&family=Noto+Sans+TC:wght@400;700&display=swap');
@import 'utils/constants';

@font-face {
  font-family: Stratos;
  font-weight: 700;
  font-style: italic;
  src: url(https://cdn.iwgb.org.uk/assets/stratosweb-bolditalic.woff);
}

$primary: $roovolt-purple;
$secondary: $roovolt-green;
$link-color: black;
$link-decoration: underline;
$form-switch-color: #c6acd2;
@import '~bootstrap/scss/bootstrap';
@import 'utils/typography';
@import 'utils/reset';

a:hover {
  color: inherit;
}

.text-purple {
  color: $roovolt-purple;
}

.bg-purple {
  background-color: $roovolt-purple;
}

.bg-grey {
  background-color: $unwrapped-grey;
}

.font-size-stratos-body {
  font-size: 120%;
}

.transitioning {
  overflow: hidden;
}

.disabled {
  pointer-events: none;
}

[disabled] {
  cursor: not-allowed;
}
