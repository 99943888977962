.btn-reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;

  color: inherit;
  font: inherit;

  line-height: normal;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  -webkit-appearance: none;

  text-align: inherit;

  border-radius: 0 !important;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:hover {
    color: inherit;
    background: transparent;
  }

  &:focus, &:active:focus, &:active {
    box-shadow: none;
    background-color: transparent;
  }
}

[disabled], .disabled {
  cursor: not-allowed;
}
