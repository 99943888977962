@import 'src/utils/utils';

.bottom {
  margin-top: -1rem;

  @include media-breakpoint-only(lg) {
    margin-top: -.5rem;
  }

  @include media-breakpoint-only(xs) {
    margin-top: -.5rem;
  }
}
