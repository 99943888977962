@import 'src/utils/utils';
@import 'src/components/HighlightText/highlight';

$breakpoints: (
  // (breakpoint, font-size, highlight height, bottom margin)
  (xs, 13vw, 10vw, .25rem) // xs
  (sm, 4.5rem, 3.5rem, .5rem) // sm
  (md, 6rem, 4.5rem, .75rem) // md
  (lg, 3.5rem, 3rem, .5rem) // lg
  (xl, 4.5rem, 3.5rem, .5rem) // xl
  (xxl, 5rem, 4rem, .75rem) // xxl
);

.highlight {
  @each $breakpoint in $breakpoints {
    @include media-breakpoint-only(nth($breakpoint, 1)) {
      @include highlight(nth($breakpoint, 2), nth($breakpoint, 3));

      &.bottom {
        margin-left: nth($breakpoint, 4);
      }
    }
  }
}
